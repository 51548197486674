<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.plan/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.content" size="small" clearable placeholder="按标题查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.show_type" placeholder="按展示位置查询" clearable size="small">
					<el-option v-for="(item,index) in types.show_type" :label="item" :value="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.terminal" placeholder="按终端查询" clearable size="small">
					<el-option v-for="(item,index) in types.terminal" :label="item" :value="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.city" placeholder="按区域查询" clearable size="small">
					<el-option v-for="(item,index) in types.city" :label="item" :value="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.status" placeholder="按状态查询" clearable size="small">
					<el-option v-for="(item,index) in types.plan_type" :label="item" :value="index">
					</el-option>
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="content" title="标题" align="center" />
			<vxe-table-column slot="table-item" field="show_type" title="展示位置" align="center">
				<template v-slot="{ row }">
					<span>{{types.show_type[row.show_type]||'未知'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="展示内容" align="center">
				<template v-slot="{ row }">
					<span v-if="row.type ==20"> {{row.details}} </span>
					<el-image style="width: 100px; height: 48px;position: relative;left: -15px" :src="row.urlone"
						v-if="row.type ==10 && row.urlone" :preview-src-list="viewImgList(row)" fit="contain">
					</el-image>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="侧边小图" align="center">
				<template v-slot="{ row }">
					<el-image style="width: 50px; height: 48px;position: relative;" :src="row.mini_url"
						v-if="row.mini_url" :preview-src-list="[row.mini_url]" fit="contain">
					</el-image>
				</template>
			</vxe-table-column>

			<vxe-table-column slot="table-item" title="跳转地址" align="center">
				<template v-slot="{ row }">
					<span v-if="row.tz_type!=10"> {{row.url}} </span>
					<el-image v-if="row.tz_type==10 && row.urltwo"
						style="width: 100px; height: 48px;position: relative;left: -15px" :src="row.urltwo"
						:preview-src-list="viewImgListTwo(row)" fit="contain">
					</el-image>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="position" title="终端" align="center">
				<template v-slot="{ row }">
					<span>{{types.terminal[row.terminal]||'未知'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="city" title="推广区域" align="center">
				<template v-slot="{ row }">
					<span>{{types.city[row.city]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="有效时间区间" align="center">
				<template v-slot="{ row }">
					{{row.start_time}}<br>{{row.end_time}}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="status" title="状态" align="center">
				<template v-slot="{ row }">
					<span>{{types.plan_type[row.status]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="viewtype" title="弹出次数" align="center">
				<template v-slot="{ row }">
					<span>{{types.viewtype[row.viewtype]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="sort_num" title="排序↑" align="center" />
			<vxe-table-column slot="table-item" title="操作" align="center">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('adverts.adlist/view')">编辑
					</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增 -->
		<el-dialog title="新增" :visible.sync="addDialogShow" :close-on-click-modal="false" :close-on-press-escape="false"
			width="600px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="100px" class="form">
				<el-form-item label="标题" prop="content">
					<el-input v-model="addDialogFormData.content" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="展示位置" prop="show_type">
					<el-select v-model="addDialogFormData.show_type" size="small">
						<el-option v-for="(item,index) in types.show_type" :label="item" :value="parseInt(index)">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="展示内容" prop="type">
					<el-radio-group v-model="addDialogFormData.type">
						<el-radio :label="10">图片</el-radio>
						<el-radio :label="20">文本</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="正文" prop="details" v-if="addDialogFormData.type == 20">
					<el-input v-model="addDialogFormData.details" type="textarea" rows='5' clearable
						style="width:80%" />
				</el-form-item>
				<el-form-item label="跳转类型" prop="tz_type" v-if="addDialogFormData.type == 10">
					<el-radio-group v-model="addDialogFormData.tz_type">
						<el-radio :label="0">不跳转</el-radio>
						<el-radio :label="10">图片</el-radio>
						<el-radio :label="20">链接</el-radio>
						<el-radio :label="30">程序内地址</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="弹出次数" prop="viewtype">
					<el-select v-model="addDialogFormData.viewtype" placeholder="弹出次数" clearable size="small">
						<el-option v-for="(item,index) in types.viewtype" :label="item" :value="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="上传弹窗图" prop="plan_name" v-if="addDialogFormData.type == 10">
					<upload :fileList="imageList" @getFileInfo="getFileInfo">
					</upload>
					<label style="position: relative;">
						<label>
							<template v-if="addDialogFormData.show_type==10">600*800</template>
							<template v-else-if="addDialogFormData.show_type==20">600*300</template>
							<template v-else>800*200</template>px(宽*高)<span style="color: red">(最大1M)</span><br></label>
						<span v-if="addDialogFormData.tz_type==10" style="color: red;font-size: 10px;">第一张图片为首页弹窗图片,第二张图片为详情页,按序单张上传
						</span>
					</label>
				</el-form-item>

				<el-form-item label="链接" prop="content"
					v-if="addDialogFormData.type == 10 && (addDialogFormData.tz_type==20 || addDialogFormData.tz_type==30)">
					<el-input v-model="addDialogFormData.url" style="width: 80%;"></el-input>
				</el-form-item>

				<el-form-item label="侧边快捷图标" prop="plan_name" v-if="addDialogFormData.show_type == 10">
					<upload :fileList="imageList2" @getFileInfo="getFileInfo2">
					</upload>
				</el-form-item>

				<el-form-item label="终端" prop="terminal">
					<el-select v-model="addDialogFormData.terminal" placeholder="跳转类型" clearable size="small">
						<el-option label="全部" :value="10">
						</el-option>
						<el-option label="小程序" :value="20">
						</el-option>
						<el-option label="App" :value="30">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="弹窗区域" prop="city">
					<el-select v-model="addDialogFormData.city" placeholder="弹窗区域" clearable size="small">
						<el-option label="全部" :value="10">
						</el-option>
						<el-option label="苏州" :value="20">
						</el-option>
						<el-option label="无锡" :value="30">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="时间区间" prop="select_time">
					<el-date-picker class="from-item-width" unlink-panels v-model="addDialogFormData.select_time"
						type="daterange" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="奖品" prop="prize">
					<el-input v-model="addDialogFormData.prize" placeholder="奖品ID,领取间隔冷却天;例:  10,1" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="排序(小排前)" prop="sort_num">
					<el-input-number :precision="0" :min="0" v-model="addDialogFormData.sort_num" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()"
					v-if="$hasAccess('market.plan/add')">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑弹窗信息" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="600px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="标题" prop="content">
					<el-input v-model="editDialogFormData.content" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="展示位置" prop="show_type">
					<el-select v-model="editDialogFormData.show_type" size="small">
						<el-option v-for="(item,index) in types.show_type" :label="item" :value="parseInt(index)">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="展示内容" prop="type">
					<el-radio-group v-model="editDialogFormData.type">
						<el-radio :label="10">图片</el-radio>
						<el-radio :label="20">文本</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="正文" prop="details" v-if="editDialogFormData.type == 20">
					<el-input v-model="editDialogFormData.details" type="textarea" rows='5' clearable
						style="width:80%" />
				</el-form-item>
				<el-form-item label="跳转类型" prop="tz_type" v-if="editDialogFormData.type == 10">
					<el-radio-group v-model="editDialogFormData.tz_type">
						<el-radio :label="0">不跳转</el-radio>
						<el-radio :label="10">图片</el-radio>
						<el-radio :label="20">链接</el-radio>
						<el-radio :label="30">程序内地址</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="弹出次数" prop="viewtype">
					<el-select v-model="editDialogFormData.viewtype" placeholder="弹出次数" clearable size="small">
						<el-option v-for="(item,index) in types.viewtype" :label="item" :value="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="上传弹窗图" prop="plan_name" v-if="editDialogFormData.type == 10">
					<upload :fileList="imageList" @getFileInfo="getFileInfoEdit">
					</upload>
					<label style="position: relative;top: -20px;">
						<label>
							<template v-if="editDialogFormData.show_type==10">600*800</template>
							<template v-else-if="editDialogFormData.show_type==20">600*300</template>
							<template v-else>800*200</template>px(宽*高)<span style="color: red">(最大1M)</span></label><br>
						<span v-if="editDialogFormData.tz_type==10" style="color: red;font-size: 10px;">第一张图片为首页弹窗图片,第二张图片为详情页,按序单张上传</span>
					</label>
				</el-form-item>
				<el-form-item label="链接" prop="content"
					v-if="editDialogFormData.type == 10 && (editDialogFormData.tz_type==20 || editDialogFormData.tz_type==30)">
					<el-input v-model="editDialogFormData.url" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="侧边快捷图标" prop="plan_name">
					<upload :fileList="imageList2" @getFileInfo="getFileInfoEdit2">
					</upload>
				</el-form-item>
				<el-form-item label="终端" prop="terminal">
					<el-select v-model="editDialogFormData.terminal" placeholder="终端类型" clearable size="small">
						<el-option label="全部" :value="10">
						</el-option>
						<el-option label="小程序" :value="20">
						</el-option>
						<el-option label="App" :value="30">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="弹窗区域" prop="city">
					<el-select v-model="editDialogFormData.city" placeholder="弹窗区域" clearable size="small">
						<el-option label="全部" :value="10">
						</el-option>
						<el-option label="苏州" :value="20">
						</el-option>
						<el-option label="无锡" :value="30">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="时间区间" prop="select_time">
					<el-date-picker class="from-item-width" style="width: 100%" unlink-panels
						v-model="editDialogFormData.select_time" type="daterange" clearable range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item label="奖品" prop="prize">
					<el-input v-model="editDialogFormData.prize" placeholder="奖品ID,间隔天;例:  10,1" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="排序(小排前)" prop="sort_num">
					<el-input-number :precision="0" :min="0" v-model="editDialogFormData.sort_num" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()"
					:disabled="editDisabled" v-if="$hasAccess('adverts.adlist/save')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'
	export default {
		name: 'market-plan-list',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				// 表格信息
				tableName: '弹窗信息',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				groupList: [],
				dates: [],
				status: 0,
				imageList: [],
				imageList2: [],
				selecttime: {},
				selecttimeedit: {},
				groupLoading: false,
				searchFormData: {},
				types: {
					plan_type: {
						10: '有效',
						20: '失效',
						30: '已完成'
					},
					show_type: {
						10: '弹窗',
						20: 'Banner',
						30: '开始充电⻚',
						40: '充电中⻚',
						50: '订单详情⻚',
					},
					type: {
						10: '图片',
						20: '文本'
					},
					status: {
						10: '关闭',
						20: '开启',
						30: "关闭"
					},
					terminal: {
						10: '全部',
						20: '小程序',
						30: "app"
					},
					city: {
						10: '全部',
						20: '苏州',
						30: '无锡'
					},
					viewtype: {
						1:"每次打开",
						2:"每天1次",
						3:"仅1次"
					}
				},
				// 新增
				addDialogShow: false,
				addDialogFormData: {},
				rules: {
					content: [{
						required: true,
						message: '请输入标题',
						trigger: 'change'
					}],
					type: [{
						required: true,
						message: '请选择弹出方式',
						trigger: 'change'
					}]
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {},
				editDialogFormOriData: {},
				editNeedData: ['ad_id', 'content', 'position', 'group_id', 'select_time', 'status']
			}
		},
		computed: {
			editDisabled() {
				return false
			},
			viewImgList() {
				return function(row) {
					return [row.urlone]
				}
			},
			viewImgListTwo() {
				return function(row) {
					return [row.urltwo]
				}
			},
		},
		mounted() {
			this.init();
		},
		activated() {
			this.init();
		},
		methods: {
			// 初始化请求
			init() {
				this.remoteGroup();
			},
			// 表格列表
			async getList(currentPage, pageSize) {
				if (this.dates && this.dates.length == 2) {
					this.searchFormData.start_time = parseInt(this.dates[0].getTime() / 1000);
					this.searchFormData.end_time = parseInt(this.dates[1].getTime() / 1000);
				} else {
					this.searchFormData.start_time = '';
					this.searchFormData.end_time = '';
				}
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				const res = await this.$api.Ad.GetAdList(params, searchData)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			async remoteGroup(query) {
				if (query !== '') {
					this.groupLoading = true
					const params = {
						token: this.$store.state.user.token,
						page: 1,
						size: 1000
					}
					const res = await this.$api.Device.GetGroupList(params, {
						group_name: query
					})
					this.groupList = res.data
					this.groupLoading = false
				} else {
					this.groupList = []
				}
			},
			// 重置搜索
			clearSearch() {
				this.dates = [];
				this.searchFormData = {}
			},
			// 附件
			getFileInfo(fileInfo) {
				if (this.addDialogFormData.attach_id && this.addDialogFormData.attach_id == fileInfo && this
					.addDialogFormData.attach_id.indexOf(",") == "-1") {
					//执行删除
					const params = {
						token: this.$store.state.user.token,
						attach_id: this.addDialogFormData.attach_id
					}
					this.$api.Ad.DeleteImg(params);
					this.addDialogFormData.attach_id = "";
					return;
				}
				this.addDialogFormData.attach_id = fileInfo;
			},
			// 附件
			getFileInfo2(fileInfo) {
				if (this.addDialogFormData.mini_attach_id && this.addDialogFormData.mini_attach_id == fileInfo && this
					.addDialogFormData.mini_attach_id.indexOf(",") == "-1") {
					//执行删除
					const params = {
						token: this.$store.state.user.token,
						mini_attach_id: this.addDialogFormData.mini_attach_id
					}
					this.$api.Ad.DeleteImg(params);
					this.addDialogFormData.mini_attach_id = "";
					return;
				}
				this.addDialogFormData.mini_attach_id = fileInfo;
			},
			// 附件
			getFileInfoEdit(fileInfo) {
				this.editDialogFormData.attach_id_new = fileInfo;
			},
			// 附件2
			getFileInfoEdit2(fileInfo) {
				this.editDialogFormData.mini_attach_id_new = fileInfo;
			},
			// 重置form
			resetFormData() {
				this.dates = [];
				this.addDialogFormData = {
					show_type: 10,
					terminal: 10,
					city: 10,
					viewtype: "1",
				}
				this.imageList = [];
				this.imageList2 = [];
				this.editDialogFormData = {}
				this.editDialogFormOriData = {}
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields()
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.addDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData()
				const params = {
					token: this.$store.state.user.token,
					ad_id: row.ad_id
				}
				const res = await this.$api.Ad.GetAdDetail(params);
				if (res) {
					const {
						ad_id,
						content,
						show_type,
						type,
						tz_type,
						select_time,
						terminal,
						attach_id,
						mini_attach_id,
						url,
						details,
						prize,
						city,
						sort_num,
						viewtype
					} = res
					this.editDialogFormData = {
						ad_id,
						content,
						show_type,
						type,
						tz_type,
						select_time,
						terminal,
						attach_id,
						mini_attach_id,
						url,
						details,
						prize,
						city,
						sort_num,
						viewtype
					}
					this.editDialogFormOriData = {
						ad_id,
						content,
						show_type,
						type,
						tz_type,
						select_time,
						terminal,
						attach_id,
						mini_attach_id,
						url,
						details,
						prize,
						city,
						sort_num,
						viewtype
					}
					this.editDialogFormData.viewtype = this.editDialogFormData.viewtype.toString();
					this.editDialogFormOriData.viewtype = this.editDialogFormOriData.viewtype.toString();
				}
				this.editDialogShow = true
			},
			async upStatus(row) {
				if (row.status == 10) {
					this.status = 20
				} else if (row.status == 20) {
					this.status = 10
				} else {
					this.status = 20
				}
				const params = {
					token: this.$store.state.user.token,
					ad_id: row.ad_id,
					status: this.status
				}
				const res = await this.$api.Ad.upStatus(params);
				this.$notify({
					title: '成功',
					message: '更改状态成功',
					type: 'success'
				})
				this.$refs.xTable.refreshTable()
			},
			// 删除
			deleteOne(row) {
				this.$confirm('是否确定删除该条弹窗?', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						ad_id: row.ad_id,
						attach_id: row.attach_id
					}
					await this.$api.Ad.DeleteAd(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.addDialogShow = false
				this.editDialogShow = false
			},
			// 新增保存
			saveAdd() {
				if (!this.addDialogFormData.type) {
					this.$refs.addForm.validateField('type');
					return;
				}
				if (!this.addDialogFormData.content) {
					this.$refs.addForm.validateField('content');
					return;
				}
				if (!this.addDialogFormData.select_time || this.addDialogFormData.select_time.length != 2) {
					this.$message.warning('请选择时间段')
					return;
				}
				if (this.addDialogFormData.type == 10) {
					if (!this.addDialogFormData.attach_id) {
						this.$message.warning('请上传图片')
						return;
					};
				}
				this.addAd();
			},
			// 提交记录
			async addAd() {
				this.selecttime.start_time = parseInt(this.addDialogFormData.select_time[0].getTime() / 1000);
				this.selecttime.end_time = parseInt(this.addDialogFormData.select_time[1].getTime() / 1000);
				const form = {
					show_type: this.addDialogFormData.show_type,
					type: this.addDialogFormData.type,
					content: this.addDialogFormData.content,
					tz_type: this.addDialogFormData.tz_type,
					terminal: this.addDialogFormData.terminal,
					url: this.addDialogFormData.url,
					details: this.addDialogFormData.details,
					prize: this.addDialogFormData.prize,
					attach_id: this.addDialogFormData.attach_id,
					city: this.addDialogFormData.city,
					sort_num: this.addDialogFormData.sort_num,
					start_time: this.selecttime.start_time,
					end_time: this.selecttime.end_time,
					viewtype: this.addDialogFormData.viewtype,
				}
				const params = {
					token: this.$store.state.user.token,
					form: JSON.stringify(form),
				}
				await this.$api.Ad.AddAd(params);
				this.$notify({
					title: "成功",
					message: "添加成功",
					type: "success",
				});
				this.closeDialog();
				this.getList();
			},
			// 编辑保存
			saveEdit() {
				if (!this.editDialogFormData.type) {
					this.$refs.editForm.validateField('type');
					return;
				}
				if (!this.editDialogFormData.content) {
					this.$refs.editForm.validateField('content');
					return;
				}
				if (!this.editDialogFormData.select_time || this.editDialogFormData.select_time.length != 2) {
					this.$message.warning('请选择时间段')
					return;
				}
				this.$refs.editForm.validate(async valid => {
					if (this.editDialogFormData.select_time[0].length == undefined) {
						this.editDialogFormData.start_time = parseInt(this.editDialogFormData.select_time[0]
							.getTime() / 1000);
						this.editDialogFormData.end_time = parseInt(this.editDialogFormData.select_time[1]
							.getTime() / 1000);
					} else {
						this.editDialogFormData.start_time = this.editDialogFormData.select_time[0];
						this.editDialogFormData.end_time = this.editDialogFormData.select_time[1];
					}
					if (valid) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						await this.$api.Ad.editGroupBill(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
